import { useState, useCallback, useMemo } from 'react';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useMount } from 'react-use';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
import { arrayUnion, onSnapshot, setDoc } from 'firebase/firestore';
import { FrFirebase } from '~/modules/SDK/firebase/FrFirebase';
//基本上一個投顧給予5組自選股就足夠了,且一組有20檔商品,總共會有100檔自選股
/** FireBase firestore "symbol_watch_list_group" */
export const useSymbolWatchListGroup = (groupName) => {
    //
    const { meUserState } = useMeStore();
    const uid = meUserState?.uid || undefined;
    const [symbolList, setSymbolList] = useState();
    const symbolListArray = symbolList?.[groupName];
    /** 獲取symbol_watch_list_group中的以uid分類個人儲存的文件 */
    const getSymbolListGroupData = useCallback(() => {
        if (uid) {
            onSnapshot(FrFirebase.getDocRef('symbol_watch_list_group', uid), docSnap => {
                setSymbolList(docSnap.data());
            });
        }
    }, [uid]);
    /** 第一次使用時創建xxxx_group空文件 */
    const creatGroupNameFile = useCallback(() => {
        if (symbolListArray === undefined && uid !== undefined) {
            setDoc(FrFirebase.getDocRef('symbol_watch_list_group', uid), {
                [groupName]: {
                    group0: { name: arrayUnion(), symbols: arrayUnion() },
                    group1: { name: arrayUnion(), symbols: arrayUnion() },
                    group2: { name: arrayUnion(), symbols: arrayUnion() },
                    group3: { name: arrayUnion(), symbols: arrayUnion() },
                    group4: { name: arrayUnion(), symbols: arrayUnion() },
                },
            }, { merge: true }).then(() => {
                FrGa.event({ 自選股__創建空文件: { groupName } });
            });
        }
    }, [symbolListArray, uid, groupName]);
    /** 新增商品至指定的群組 */
    const addSymbol = useCallback((symbol, targetGroupName) => {
        const currentGroup = symbolListArray?.[targetGroupName];
        const targetGroup = currentGroup
            ? Object.values(currentGroup).find(group => group.name === targetGroupName)
            : { name: '', symbols: [] };
        if (symbolListArray === undefined ||
            ((targetGroup?.symbols?.length ?? 0) < 20 && !targetGroup?.symbols?.includes(symbol))) {
            const updatedGroup = {
                [groupName]: {
                    [targetGroupName]: {
                        ...targetGroup,
                        name: arrayUnion(),
                        symbols: arrayUnion(symbol), // 這裡使用 arrayUnion 來附加新的項目
                    },
                },
            };
            setDoc(FrFirebase.getDocRef('symbol_watch_list_group', uid), updatedGroup, {
                merge: true,
            }).then(() => {
                FrGa.event({ 自選股__新增自選股: { groupName: targetGroupName, target: [symbol] } });
            });
        }
    }, [symbolListArray, uid, groupName]);
    /** 新增商品至指定的群組 */
    const replaceSymbol = useCallback((symbolArray, targetGroupName) => {
        const currentGroup = symbolListArray?.[targetGroupName];
        const targetGroup = currentGroup
            ? Object.values(currentGroup).find(group => group.name === targetGroupName)
            : { name: '', symbols: [] };
        if (symbolListArray === undefined || (targetGroup?.symbols?.length ?? 0) < 20) {
            const updatedGroup = {
                [groupName]: {
                    [targetGroupName]: {
                        ...targetGroup,
                        name: arrayUnion(),
                        symbols: symbolArray, // 這裡使用 arrayUnion 來附加新的項目
                    },
                },
            };
            setDoc(FrFirebase.getDocRef('symbol_watch_list_group', uid), updatedGroup, {
                merge: true,
            }).then(() => {
                FrGa.event({ 自選股__新增自選股: { groupName: targetGroupName, target: symbolArray } });
            });
        }
    }, [symbolListArray, uid, groupName]);
    /** 在指定群組中移除單一商品 */
    const removeSymbol = useCallback((symbol, targetGroupName) => {
        const currentGroup = symbolListArray?.[targetGroupName];
        const targetGroup = currentGroup
            ? Object.values(currentGroup)?.find(group => group.name === targetGroupName)
            : { name: '', symbols: [] };
        const symbolListRemove = currentGroup?.symbols ?? [];
        const index = symbolListRemove?.indexOf(symbol);
        symbolListRemove?.splice(index, 1);
        const updatedGroup = {
            [groupName]: {
                [targetGroupName]: {
                    ...targetGroup,
                    name: arrayUnion(),
                    symbols: symbolListRemove,
                },
            },
        };
        setDoc(FrFirebase.getDocRef('symbol_watch_list_group', uid), updatedGroup, {
            merge: true,
        }).then(() => {
            FrGa.event({
                自選股__移除自選股: {
                    groupName,
                    replace: symbolListRemove,
                    target: symbolListRemove || [],
                },
            });
        });
    }, [symbolListArray, groupName, uid]);
    /** 重新面名指定的群組名稱 */
    const renameWatchListGroup = useCallback((newName, targetGroupName) => {
        const currentGroup = symbolListArray?.[targetGroupName];
        const targetGroup = currentGroup
            ? Object.values(currentGroup)?.find(group => group.name === targetGroupName)
            : { name: '', symbols: [] };
        const symbolListRename = [newName];
        const updatedGroup = {
            [groupName]: {
                [targetGroupName]: {
                    ...targetGroup,
                    name: symbolListRename,
                    symbols: arrayUnion(),
                },
            },
        };
        setDoc(FrFirebase.getDocRef('symbol_watch_list_group', uid), updatedGroup, {
            merge: true,
        });
    }, [groupName, symbolListArray, uid]);
    useMount(() => {
        getSymbolListGroupData();
        creatGroupNameFile();
    });
    return useMemo(() => {
        return {
            state: {
                symbolListNameArray: symbolListArray,
            },
            acts: {
                getSymbolListGroupData,
                addSymbol,
                replaceSymbol,
                removeSymbol,
                renameWatchListGroup,
            },
        };
    }, [
        symbolListArray,
        getSymbolListGroupData,
        addSymbol,
        replaceSymbol,
        removeSymbol,
        renameWatchListGroup,
    ]);
};
