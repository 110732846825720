import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const SignalWall = memo(function SignalWall() {
    return <classes.container>-</classes.container>;
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
};
