export const calculateStats = (data) => {
    const stats = [];
    data.forEach(transaction => {
        const { symbol: symbol, netProfit: netProfit, closePositionCoverQuantity: closePositionCoverQuantity, closePositionSellQuantity: closePositionSellQuantity, openPositionBuyQuantity: openPositionBuyQuantity, openPositionShortQuantity: openPositionShortQuantity, } = transaction;
        // Find existing stats entry for this symbol
        const existingStatsIndex = stats.findIndex(stat => stat.symbol === symbol);
        if (existingStatsIndex === -1) {
            // Create new entry if symbol not found
            const newStats = {
                symbol,
                totalProfit: 0,
                totalLoss: 0,
                totalWin: 0,
                totalQuantity: 0,
                totalNewBuyQuantity: 0,
                totalNewShortQuantity: 0,
                tradeCount: 0,
                winRate: 0,
                profitLossRatio: 0,
                averageProfit: 0,
                averageWin: 0,
                averageLoss: 0,
            };
            stats.push(newStats);
        }
        // Get the stats entry for this symbol
        const statsEntry = stats[existingStatsIndex === -1 ? stats.length - 1 : existingStatsIndex];
        // Update statistics
        const quantity = closePositionCoverQuantity +
            closePositionSellQuantity +
            openPositionBuyQuantity +
            openPositionShortQuantity;
        statsEntry.totalQuantity += quantity;
        if (netProfit > 0) {
            statsEntry.totalProfit += netProfit;
            statsEntry.totalWin += netProfit;
            statsEntry.winRate += 1;
        }
        else {
            statsEntry.totalProfit += netProfit;
            statsEntry.totalLoss += netProfit;
        }
        statsEntry.totalNewBuyQuantity += openPositionBuyQuantity;
        statsEntry.totalNewShortQuantity += openPositionShortQuantity;
        statsEntry.tradeCount += 1;
        // Calculate average values
        const averageProfit = statsEntry.totalProfit / statsEntry.tradeCount;
        const averageLoss = statsEntry.totalLoss / (statsEntry.tradeCount - statsEntry.winRate);
        const averageWin = statsEntry.totalWin / statsEntry.winRate;
        statsEntry.profitLossRatio = averageWin / Math.abs(averageLoss) || 0;
        statsEntry.averageProfit = averageProfit || 0;
        statsEntry.averageLoss = averageLoss || 0;
        statsEntry.averageWin = averageWin || 0;
    });
    // Calculate win rates for each symbol
    stats.forEach(statsEntry => {
        statsEntry.winRate = (statsEntry.winRate / statsEntry.tradeCount) * 100 || 0;
    });
    return stats;
};
