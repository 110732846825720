import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useState } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import dayAPI from '~/utils/dayAPI';
import _, { sortBy } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { TradeAnalyzeProfitChart } from '../dayTradeAnalyze/TradeAnalyzeProfitChart';
import { calculateStats } from '../dayTradeAnalyze/calculateStats';
import { FileUpload } from '../dayTradeAnalyze/FileUpload';
import { tradeAnalyzeSummary } from '../dayTradeAnalyze/tradeSummary';
const tradeAnalyzeStore = proxy({
    chartBaseDate: '',
    tradeType: 'dayTrade',
    sortType: 'totalProfit',
});
const newTransactionData = (originData) => {
    const newSetData = [];
    for (let i = 0; i < originData.length; i++) {
        const currentItem = originData[i];
        if (currentItem['itemNumber']) {
            const matchingCloseOut = originData.find((item, index) => index > i && item['itemNumber'] === '' && item['symbol'] === currentItem['symbol']);
            if (matchingCloseOut) {
                newSetData.push({
                    openPositionDate: currentItem['tradeDate'],
                    openPositionTime: currentItem['tradeTime'],
                    closePositionDate: matchingCloseOut['tradeDate'],
                    //現在多了台積電夜盤 若在T+1盤23:59前平倉則會沒有日期
                    closePositionTime: matchingCloseOut['tradeTime'] !== '' ? matchingCloseOut['tradeTime'] : '08:45:00',
                    symbol: currentItem['symbol'],
                    openPositionBuyQuantity: Number(currentItem['longQuantity']),
                    openPositionShortQuantity: Number(currentItem['shortQuantity']),
                    openOrderNumber: currentItem['orderNumber'],
                    closePositionCoverQuantity: Number(matchingCloseOut['longQuantity']),
                    closePositionSellQuantity: Number(matchingCloseOut['shortQuantity']),
                    closeOrderNumber: matchingCloseOut['orderNumber'],
                    transactionFees: Number(currentItem['transactionFees']) + Number(matchingCloseOut['transactionFees']),
                    transactionTax: Number(currentItem['transactionTax']) + Number(matchingCloseOut['transactionTax']),
                    netProfit: Number(currentItem['netProfit'].replace(',', '')),
                });
                i = originData.indexOf(matchingCloseOut);
            }
        }
    }
    return newSetData;
};
/** 將原有的交易明細資料添加累積損益 */
const calculateCumulativeProfit = (data) => {
    const sortedData = _.sortBy(data, item => {
        return dayAPI(item['closePositionDate'] + ' ' + item['closePositionTime']).unix();
    });
    let cumulativeProfit = 0;
    let cumulativeQuantity = 0;
    const length = sortedData.length - 1;
    //確保平倉日期都是一樣的 取最後一筆平倉日期`sortedData.[length]['closePositionDate']`
    const closeDate = sortedData?.[length]?.['closePositionDate'];
    //額外將當沖日期傳到store給圖表用
    tradeAnalyzeStore.chartBaseDate = sortedData?.[length]?.['closePositionDate'];
    return sortedData.map(item => {
        cumulativeProfit += item['netProfit'];
        cumulativeQuantity +=
            Number(item['openPositionBuyQuantity']) +
                Number(item['openPositionShortQuantity']) +
                Number(item['closePositionSellQuantity']) +
                Number(item['closePositionCoverQuantity']);
        return {
            ...item,
            closePositionDate: closeDate,
            accumulatedProfit: cumulativeProfit,
            accumulatedQuantity: cumulativeQuantity,
            closePositionTime: dayAPI(closeDate + ' ' + item['closePositionTime']).unix(),
        };
    });
};
export const DayTradeAnalyze = () => {
    const [jsonData1, setJsonData1] = useState(data1);
    const [jsonData2, setJsonData2] = useState(data2);
    /** 平倉損益的資料基本解析 */
    const handleFileUpload1 = (data, fileIndex) => {
        const headerMapping = {
            項次: 'itemNumber',
            交易日期: 'tradeDate',
            商品名稱: 'symbol',
            買口數: 'longQuantity',
            賣口數: 'shortQuantity',
            成交價格: 'dealPrice',
            手續費: 'transactionFees',
            交易稅: 'transactionTax',
            淨損益: 'netProfit',
            委託書號: 'orderNumber',
        };
        const header = data[0];
        const jsonDataArray = data.slice(1).map(row => {
            const rowObject = {};
            header.forEach((columnName, index) => {
                // Exclude specified fields
                if (columnName.trim() !== '年月' &&
                    columnName.trim() !== '履約價格' &&
                    columnName.trim() !== 'C/P' &&
                    columnName.trim() !== '權利金收支' &&
                    columnName.trim() !== '結算價' &&
                    columnName.trim() !== '損益' &&
                    columnName.trim() !== '幣別') {
                    const englishColumnName = headerMapping[columnName.trim()] || columnName.trim();
                    rowObject[englishColumnName] = row[index] ? row[index].trim() : '';
                }
            });
            return rowObject;
        });
        setJsonData1(jsonDataArray);
    };
    /** 交易明細的資料基本解析 */
    const handleFileUpload2 = (data, fileIndex) => {
        const header = data[0];
        const headerMapping = {
            商品名稱: 'symbol',
            買賣別: 'tradingCategory',
            委託種類: 'orderType',
            交易日期: 'tradeDate',
            成交時間: 'tradeTime',
            委託序號: 'orderNumber',
        };
        const jsonDataArray = data.slice(1).map(row => {
            const rowObject = {};
            header.forEach((columnName, index) => {
                const englishColumnName = headerMapping[columnName.trim()] || columnName.trim();
                // Exclude specified fields
                if (englishColumnName !== '帳號' &&
                    englishColumnName !== '委託價' &&
                    englishColumnName !== '成交均價' &&
                    englishColumnName !== '原委託量' &&
                    englishColumnName !== '成交數量' &&
                    englishColumnName !== '來源別' &&
                    englishColumnName !== '網路單號' &&
                    englishColumnName !== '') {
                    let value = row[index] ? row[index].trim() : '';
                    // Remove =""
                    value = value.replace(/^="|\"$/g, '');
                    rowObject[englishColumnName] = value;
                }
            });
            return rowObject;
        });
        setJsonData2(jsonDataArray);
    };
    /** 將所需要的資料合併初始樣貌 */
    const mergedData = jsonData1.map(item1 => {
        const matchingData2 = jsonData2.find(item2 => item2['orderNumber'] === item1['orderNumber']);
        if (matchingData2) {
            // 匹配，添加到data1
            return {
                ...item1,
                tradeTime: matchingData2['tradeTime'],
                tradingCategory: matchingData2['tradingCategory'],
                orderType: matchingData2['orderType'],
            };
        }
        else {
            // 没有匹配，直接返回原始item1
            return {
                ...item1,
                tradeTime: '',
                tradingCategory: '',
                orderType: '',
            };
        }
    });
    //----------------------------------------------------------------
    /** 留倉資料 */
    const bandTradeData = newTransactionData(mergedData).filter(s => s.openPositionTime === '');
    /** 當沖資料 */
    const dayTradeData = newTransactionData(mergedData).filter(s => s.openPositionTime !== '');
    /** 圖表基準日期 */
    const chartBaseDate = useSnapshot(tradeAnalyzeStore).chartBaseDate;
    /** 當沖圖表的時間軸起始與終點 */
    const dayTradeDomain = [
        dayAPI(chartBaseDate).setTime(8.45).unix(),
        dayAPI(chartBaseDate).setTime(13.45).unix(),
    ];
    /** 留倉(隔日沖)圖表的時間軸起始與終點 */
    const bandTradeDomain = [
        dayAPI(chartBaseDate).setTime(8.45).unix(),
        dayAPI(chartBaseDate).setTime(9.3).unix(),
    ];
    const tradeType = useSnapshot(tradeAnalyzeStore).tradeType;
    const dataType = tradeType === 'dayTrade' ? dayTradeData : bandTradeData;
    const sortState = useSnapshot(tradeAnalyzeStore).sortType;
    const result = sortBy(calculateStats(dataType), s => -s[sortState]);
    const bandTradeInfo = tradeAnalyzeSummary(bandTradeData);
    const dayTradeInfo = tradeAnalyzeSummary(dayTradeData);
    return (<classes.container>
      <classes.dataUpLoad.container>
        <div>當沖分析</div>
        <div>
          {/** 平倉損益 */}
          <FileUpload onUpload={data => handleFileUpload1(data, 1)} fileIndex={1}/>
          {/** 歷史成交 */}
          <FileUpload onUpload={data => handleFileUpload2(data, 2)} fileIndex={2}/>
        </div>
      </classes.dataUpLoad.container>

      <div css={css `
          ${scrollbar2Css};
          ${fill_horizontal_all_center};
          grid-column: 1;
          grid-row: 2;
          width: calc(100% - 6px);
          background-color: #252736;
          border-radius: 6px;
          padding: 6px;
        `}>
        <div css={css `
            ${scrollbar2Css};
            width: 100%;
            height: 100%;
          `}>
          <div css={css `
              ${fill_horizontal_cross_center};
              height: 30px;
              gap: 4px;
            `}>
            <div onClick={() => (tradeAnalyzeStore.tradeType = 'dayTrade')}>當沖</div>
            <div onClick={() => (tradeAnalyzeStore.tradeType = 'bandTrade')}>留倉</div>
          </div>
          <div css={css `
              ${fill_horizontal_cross_center};
              height: 30px;
              gap: 4px;
            `}>
            <span>概覽</span>
            <span>損益</span>
            <span>口數</span>
            <span>勝率</span>
            <span>賺賠比</span>
          </div>
          <DataTable data={result}/>
        </div>
      </div>
      <div css={css `
          ${fill_vertical_cross_center};
          grid-column: 2;
          grid-row: 1/3;
          gap: 4px;
        `}>
        <div css={css `
            ${fill_horizontal_cross_center};
            height: 24px;
            background-color: #2e303c;
            border-radius: 4px;
          `}>
          當沖日內損益變化
        </div>
        <div css={css `
            width: 100%;
            height: 150px;
            background-color: #252735;
            border-radius: 4px;
          `}>
          <TradeAnalyzeProfitChart data={calculateCumulativeProfit(dayTradeData)} domain={dayTradeDomain}/>
        </div>
        <div css={css `
            ${fill_horizontal_cross_center};
            height: 24px;
            background-color: #2e303c;
            border-radius: 4px;
          `}>
          留倉日內損益變化(統計至09:30)
        </div>
        <div css={css `
            width: 100%;
            height: 150px;
            background-color: #252735;
            border-radius: 4px;
          `}>
          <TradeAnalyzeProfitChart data={calculateCumulativeProfit(bandTradeData)} domain={bandTradeDomain}/>
        </div>
        <div css={css `
            ${fill_horizontal_cross_center};
            height: 24px;
            background-color: #2e303c;
            border-radius: 4px;
          `}>
          加總
        </div>
        <div css={css `
            width: 100%;
            height: 150px;
            background-color: #252735;
            border-radius: 4px;
          `}>
          <TradeAnalyzeProfitChart data={calculateCumulativeProfit(newTransactionData(mergedData))} domain={bandTradeDomain}/>
        </div>
        <div css={css `
            width: 100%;
            height: calc(100% - 546px);
            border-radius: 6px;
          `}>
          <OverViewTable bandTradeDate={bandTradeInfo} dayTradeData={dayTradeInfo}/>
        </div>
      </div>
    </classes.container>);
};
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 120px 1fr;
    width: 100%;
    height: 100%;
    gap: 6px;
    background-color: #161721;
    font-size: 14px;
    padding: 4px;
  `,
    dataUpLoad: {
        container: styled.div `
      grid-column: 1/2;
      grid-row: 1;
      ${fill_vertical_cross_center};
      height: 120px;
      background-color: #252736;
    `,
    },
};
const OverViewTable = memo(function OverViewTable(props_) {
    const bandTradeInfo = props_.bandTradeDate;
    const dayTradeInfo = props_.dayTradeData;
    return (<div css={css `
          ${fill_horizontal_cross_center}
          display: grid;
          grid-template-columns: 50% 50%;
          width: 100%;
          padding: 6px;
          background-color: #252735;
        `}>
        <div css={css `
            ${fill_vertical_cross_center};
            align-items: start;
          `}>
          <div>留倉</div>
          <div>
            <div>總損益:{bandTradeInfo.totalNetProfit}</div>
            <div>總口數:{bandTradeInfo.totalQuantity}</div>
            <div>交易次數:{bandTradeInfo.totalCount}</div>
            <div>手續費:{bandTradeInfo.totalTransactionFees}</div>
            <div>交易稅:{bandTradeInfo.totalTransactionTax}</div>
            <div>勝次:{bandTradeInfo.positiveNetProfitCount}</div>
            <div>敗次:{bandTradeInfo.negativeNetProfitCount}</div>
            <div>均獲利:{bandTradeInfo.positiveNetProfitAverage.toFixed(0)}</div>
            <div>均虧損:{bandTradeInfo.negativeNetProfitAverage.toFixed(0)}</div>
            <div>
              最大獲利:
              <div>{bandTradeInfo.maxProfitTrade?.netProfit}</div>
              <div>{bandTradeInfo.maxProfitTrade?.symbol}</div>
              <div>{bandTradeInfo.maxProfitTrade?.closePositionTime}</div>
            </div>
            <div>
              最大虧損:
              <div>{bandTradeInfo.minProfitTrade?.netProfit}</div>
              <div>{bandTradeInfo.minProfitTrade?.symbol}</div>
              <div>{bandTradeInfo.minProfitTrade?.closePositionTime}</div>-
            </div>
          </div>
        </div>
        <div css={css `
            ${fill_vertical_cross_center};
            align-items: start;
          `}>
          <div>當沖</div>
          <div>
            <div>總損益:{dayTradeInfo.totalNetProfit}</div>
            <div>總口數:{dayTradeInfo.totalQuantity}</div>
            <div>交易次數:{dayTradeInfo.totalCount}</div>
            <div>手續費:{dayTradeInfo.totalTransactionFees}</div>
            <div>交易稅:{dayTradeInfo.totalTransactionTax}</div>
            <div>勝次:{dayTradeInfo.positiveNetProfitCount}</div>
            <div>敗次:{dayTradeInfo.negativeNetProfitCount}</div>
            <div>均獲利:{dayTradeInfo.positiveNetProfitAverage.toFixed(0)}</div>
            <div>均虧損:{dayTradeInfo.negativeNetProfitAverage.toFixed(0)}</div>
            <div>
              最大獲利:
              <div>{dayTradeInfo.maxProfitTrade?.netProfit}</div>
              <div>{dayTradeInfo.maxProfitTrade?.symbol}</div>
              <div>{dayTradeInfo.maxProfitTrade?.closePositionTime}</div>
            </div>
            <div>
              最大虧損:
              <div>{dayTradeInfo.minProfitTrade?.netProfit}</div>
              <div>{dayTradeInfo.minProfitTrade?.symbol}</div>
              <div>{dayTradeInfo.minProfitTrade?.closePositionTime}</div>
            </div>
          </div>
        </div>
      </div>);
});
const DataTable = memo(function DataTable(props) {
    const result = props.data;
    return (<div css={css `
          ${fill_vertical_cross_center};
        `}>
        <div css={css `
            ${fill_horizontal_cross_center};
            height: 32px;
            display: grid;
            grid-template-columns: 19% repeat(9, 9%);
            background-color: #2e303d;
            padding: 0 6px;
            & > span {
              ${fill_horizontal_cross_center};
            }
          `}>
          <span>商品</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'totalProfit')}>損益</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'totalQuantity')}>交易口數</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'totalNewBuyQuantity')}>做多比例</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'totalNewShortQuantity')}>
            做空比例
          </span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'averageProfit')}>均交易</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'averageWin')}>均獲利</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'averageLoss')}>均虧損</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'profitLossRatio')}>賺賠比</span>
          <span onClick={() => (tradeAnalyzeStore.sortType = 'winRate')}>勝率</span>
        </div>
        <div css={css `
            ${fill_vertical_cross_center};
            height: calc(100% - 32px);
          `}>
          {result.map(productName => {
            /** 交易筆數 */
            //const tradeCount = result[productName].tradeCount
            /** 交易口數 */
            const totalQuantity = productName.totalQuantity;
            /** 作多比例 */
            const longRatio = productName.totalNewBuyQuantity / (totalQuantity / 2);
            /** 做空比例 */
            const shortRatio = 1 - productName.totalNewBuyQuantity / (totalQuantity / 2);
            /** 勝率 */
            const winRete = productName.winRate;
            /** 平均虧損 */
            const averageLoss = productName.averageLoss;
            /** 平均獲利 */
            const averageWin = productName.averageWin;
            /** 平均交易 */
            const averageProfit = productName.averageProfit;
            /** 勝率 */
            const profitLossRatio = productName.profitLossRatio;
            return (<div key={productName.symbol} css={css `
                  ${fill_horizontal_all_center};
                  height: 32px;
                  display: grid;
                  grid-template-columns: 19% repeat(9, 9%);
                  & > div {
                    ${fill_horizontal_all_center};
                  }
                `}>
                <span>{productName.symbol}</span>
                <span css={css `
                    color: ${productName.totalProfit > 0 ? '#ff0000' : '#00aa00'};
                  `}>
                  {productName.totalProfit}
                </span>
                <span>{totalQuantity}</span>
                <span>{(longRatio * 100).toFixed(1)}%</span>
                <span>{(shortRatio * 100).toFixed(1)}%</span>
                <span>{averageProfit.toFixed(0)}</span>
                <span>{averageWin.toFixed(0)}</span>
                <span>{averageLoss.toFixed(0)}</span>
                <span>{profitLossRatio.toFixed(1)}</span>
                <span>{winRete.toFixed(2)}%</span>
              </div>);
        })}
        </div>
      </div>);
});
const data1 = [
    {
        itemNumber: '1',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '10',
        shortQuantity: '',
        dealPrice: '107',
        transactionFees: '60',
        transactionTax: '40',
        netProfit: '139,790',
        orderNumber: 'caa2f',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '10',
        dealPrice: '114',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '',
        orderNumber: 'caabP',
    },
    {
        itemNumber: '2',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '18',
        transactionTax: '12',
        netProfit: '35,937',
        orderNumber: 'caa6V',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '114',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caacc',
    },
    {
        itemNumber: '3',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '7',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '42',
        transactionTax: '28',
        netProfit: '83,853',
        orderNumber: 'caa6V',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '7',
        dealPrice: '114',
        transactionFees: '42',
        transactionTax: '35',
        netProfit: '',
        orderNumber: 'caacc',
    },
    {
        itemNumber: '4',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '102',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '22,979',
        orderNumber: 'caaxu',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacK',
    },
    {
        itemNumber: '5',
        tradeDate: '2024/1/19',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '240',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '17,968',
        orderNumber: 'cabkk',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '249',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caacT',
    },
    {
        itemNumber: '6',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '30',
        transactionTax: '20',
        netProfit: '54,895',
        orderNumber: 'caa6V',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '7',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '10,979',
        orderNumber: 'caa6W',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '8',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '10,979',
        orderNumber: 'caa6W',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '9',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '10,979',
        orderNumber: 'caa6W',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '10',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '486',
        orderNumber: 'cabgi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '630',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '',
        orderNumber: 'caaen',
    },
    {
        itemNumber: '11',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '18',
        transactionTax: '3',
        netProfit: '1,458',
        orderNumber: 'cabgi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '630',
        transactionFees: '18',
        transactionTax: '3',
        netProfit: '',
        orderNumber: 'caaen',
    },
    {
        itemNumber: '12',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '486',
        orderNumber: 'cabgi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '630',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '',
        orderNumber: 'caaen',
    },
    {
        itemNumber: '13',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '586',
        orderNumber: 'cabgj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '631',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '',
        orderNumber: 'caaeo',
    },
    {
        itemNumber: '14',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '24',
        transactionTax: '4',
        netProfit: '2,344',
        orderNumber: 'cabgj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '631',
        transactionFees: '24',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaeo',
    },
    {
        itemNumber: '15',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '18',
        transactionTax: '3',
        netProfit: '1,758',
        orderNumber: 'cabhn',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '631',
        transactionFees: '18',
        transactionTax: '3',
        netProfit: '',
        orderNumber: 'caaeq',
    },
    {
        itemNumber: '16',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '586',
        orderNumber: 'cabhn',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '631',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '',
        orderNumber: 'caaeq',
    },
    {
        itemNumber: '17',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '625',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '586',
        orderNumber: 'cabhn',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '631',
        transactionFees: '6',
        transactionTax: '1',
        netProfit: '',
        orderNumber: 'caaeq',
    },
    {
        itemNumber: '18',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '10,979',
        orderNumber: 'caa6W',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '19',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '108',
        transactionFees: '6',
        transactionTax: '4',
        netProfit: '10,979',
        orderNumber: 'caa6W',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacH',
    },
    {
        itemNumber: '20',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaf5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '21',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '3,912',
        orderNumber: 'caaf5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '114',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '22',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaf7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '23',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaf7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '24',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaf7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '25',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caaf7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caacD',
    },
    {
        itemNumber: '26',
        tradeDate: '2024/1/19',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '102',
        transactionFees: '24',
        transactionTax: '16',
        netProfit: '91,916',
        orderNumber: 'caaxu',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '113.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caagV',
    },
    {
        itemNumber: '27',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '284',
        transactionFees: '6',
        transactionTax: '11',
        netProfit: '2,966',
        orderNumber: 'caacy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '282.5',
        transactionFees: '6',
        transactionTax: '11',
        netProfit: '',
        orderNumber: 'caag4',
    },
    {
        itemNumber: '28',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '284.5',
        transactionFees: '6',
        transactionTax: '11',
        netProfit: '3,966',
        orderNumber: 'caadl',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '282.5',
        transactionFees: '6',
        transactionTax: '11',
        netProfit: '',
        orderNumber: 'caag4',
    },
    {
        itemNumber: '29',
        tradeDate: '2024/1/19',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '627',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '2,430',
        orderNumber: 'cabks',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '632',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaep',
    },
    {
        itemNumber: '30',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caag6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caahE',
    },
    {
        itemNumber: '31',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caag6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '113.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caahE',
    },
    {
        itemNumber: '32',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caag8',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caahE',
    },
    {
        itemNumber: '33',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-82',
        orderNumber: 'caagV',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.53',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caahE',
    },
    {
        itemNumber: '34',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-410',
        orderNumber: 'caagW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '113.53',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caahE',
    },
    {
        itemNumber: '35',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caaiK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caakM',
    },
    {
        itemNumber: '36',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caaiL',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caakM',
    },
    {
        itemNumber: '37',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '38',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '39',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '40',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '41',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '42',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '43',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caakR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caakV',
    },
    {
        itemNumber: '44',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '113.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '2,934',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '45',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '46',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '47',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '48',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '113.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '49',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caakZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caala',
    },
    {
        itemNumber: '50',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-88',
        orderNumber: 'caalc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '113',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caald',
    },
    {
        itemNumber: '51',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '6',
        shortQuantity: '',
        dealPrice: '113',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '-132',
        orderNumber: 'caalc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '6',
        dealPrice: '113',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '',
        orderNumber: 'caald',
    },
    {
        itemNumber: '52',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '53',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '54',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '55',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '56',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '57',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalj',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalo',
    },
    {
        itemNumber: '58',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '4,890',
        orderNumber: 'caalt',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '114',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caakK',
    },
    {
        itemNumber: '59',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '113.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '4,890',
        orderNumber: 'caalt',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '114',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caakK',
    },
    {
        itemNumber: '60',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '10',
        dealPrice: '114',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '-10,220',
        orderNumber: 'caalG',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '10',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '',
        orderNumber: 'caalR',
    },
    {
        itemNumber: '61',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '8',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '48',
        transactionTax: '40',
        netProfit: '-176',
        orderNumber: 'caalJ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '8',
        dealPrice: '114',
        transactionFees: '48',
        transactionTax: '40',
        netProfit: '',
        orderNumber: 'caalW',
    },
    {
        itemNumber: '62',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalJ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalW',
    },
    {
        itemNumber: '63',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caalJ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caalW',
    },
    {
        itemNumber: '64',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '10',
        dealPrice: '114',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '-220',
        orderNumber: 'caalX',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '10',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '',
        orderNumber: 'caal1',
    },
    {
        itemNumber: '65',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '10',
        dealPrice: '114',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '-10,220',
        orderNumber: 'caal5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '10',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '60',
        transactionTax: '50',
        netProfit: '',
        orderNumber: 'caamv',
    },
    {
        itemNumber: '66',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '6',
        dealPrice: '114.5',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '-6,132',
        orderNumber: 'caamy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '6',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '',
        orderNumber: 'caanf',
    },
    {
        itemNumber: '67',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caamy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanf',
    },
    {
        itemNumber: '68',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caamy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanf',
    },
    {
        itemNumber: '69',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caamy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanf',
    },
    {
        itemNumber: '70',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caamy',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanf',
    },
    {
        itemNumber: '71',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-5,032',
        orderNumber: 'caale',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '253.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caanl',
    },
    {
        itemNumber: '72',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '251',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-5,032',
        orderNumber: 'caale',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '253.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caanl',
    },
    {
        itemNumber: '73',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '74',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '75',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '76',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '114.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '77',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '78',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '79',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caamA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caanD',
    },
    {
        itemNumber: '80',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '253',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-4,064',
        orderNumber: 'caanK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '254',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaom',
    },
    {
        itemNumber: '81',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '253.5',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-2,064',
        orderNumber: 'caanO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '254',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaom',
    },
    {
        itemNumber: '82',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caaoY',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '115.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caao9',
    },
    {
        itemNumber: '83',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '7',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '42',
        transactionTax: '35',
        netProfit: '-154',
        orderNumber: 'caaoY',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '7',
        dealPrice: '115.5',
        transactionFees: '42',
        transactionTax: '35',
        netProfit: '',
        orderNumber: 'caao8',
    },
    {
        itemNumber: '84',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caao6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '115.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caao8',
    },
    {
        itemNumber: '85',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caao6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '115.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caao9',
    },
    {
        itemNumber: '86',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caao6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caao9',
    },
    {
        itemNumber: '87',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-88',
        orderNumber: 'caao6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '115.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caao9',
    },
    {
        itemNumber: '88',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '89',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '90',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '91',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '92',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '93',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '94',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '95',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '96',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caapc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caapA',
    },
    {
        itemNumber: '97',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caapu',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caapB',
    },
    {
        itemNumber: '98',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caapu',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caapB',
    },
    {
        itemNumber: '99',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapI',
    },
    {
        itemNumber: '100',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caapI',
    },
    {
        itemNumber: '101',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '8',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '48',
        transactionTax: '40',
        netProfit: '-176',
        orderNumber: 'caapE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '8',
        dealPrice: '116',
        transactionFees: '48',
        transactionTax: '40',
        netProfit: '',
        orderNumber: 'caapI',
    },
    {
        itemNumber: '102',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '103',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '104',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '105',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caapK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '106',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '6',
        dealPrice: '116',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '-132',
        orderNumber: 'caapK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '6',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '36',
        transactionTax: '30',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '107',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '115.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-5,110',
        orderNumber: 'caapR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaqn',
    },
    {
        itemNumber: '108',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaqo',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqv',
    },
    {
        itemNumber: '109',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '2,934',
        orderNumber: 'caaqo',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '116.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaqv',
    },
    {
        itemNumber: '110',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaqo',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqv',
    },
    {
        itemNumber: '111',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaqK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqM',
    },
    {
        itemNumber: '112',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaqK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqM',
    },
    {
        itemNumber: '113',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaqK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqM',
    },
    {
        itemNumber: '114',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaqK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqM',
    },
    {
        itemNumber: '115',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaqK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaqM',
    },
    {
        itemNumber: '116',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '117',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '118',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '119',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '120',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '121',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caarH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caar4',
    },
    {
        itemNumber: '122',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '123',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '124',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '125',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '126',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '127',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '128',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '129',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '130',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '131',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caar9',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caasb',
    },
    {
        itemNumber: '132',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.7',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'caarW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasm',
    },
    {
        itemNumber: '133',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.7',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'caarW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasm',
    },
    {
        itemNumber: '134',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.7',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'caarW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasm',
    },
    {
        itemNumber: '135',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '54.7',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-432',
        orderNumber: 'caarW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '54.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caasm',
    },
    {
        itemNumber: '136',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caarT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '137',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caarT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '138',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caarT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '139',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caarT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '140',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caarT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '141',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '-64',
        orderNumber: 'caasw',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '54.4',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'caas0',
    },
    {
        itemNumber: '142',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '255',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caapU',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '255',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caas5',
    },
    {
        itemNumber: '143',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '255',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caapU',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '255',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caas5',
    },
    {
        itemNumber: '144',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '145',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '146',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '116.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '3,912',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '147',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '148',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '149',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '150',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caask',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaua',
    },
    {
        itemNumber: '151',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'caasZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaue',
    },
    {
        itemNumber: '152',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'caasZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaue',
    },
    {
        itemNumber: '153',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'caasZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaue',
    },
    {
        itemNumber: '154',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'caasZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaue',
    },
    {
        itemNumber: '155',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '156',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '157',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '158',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '159',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '160',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '161',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauq',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaut',
    },
    {
        itemNumber: '162',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '54.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-1,080',
        orderNumber: 'caauW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.1',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caavg',
    },
    {
        itemNumber: '163',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caauT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caavg',
    },
    {
        itemNumber: '164',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'caauT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '54.1',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caavg',
    },
    {
        itemNumber: '165',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caauT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caavg',
    },
    {
        itemNumber: '166',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caauT',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caavg',
    },
    {
        itemNumber: '167',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caavt',
    },
    {
        itemNumber: '168',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caauE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caavV',
    },
    {
        itemNumber: '169',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caauE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caavV',
    },
    {
        itemNumber: '170',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caauE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caavV',
    },
    {
        itemNumber: '171',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'caauU',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '54.1',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caawe',
    },
    {
        itemNumber: '172',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '54.1',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '-48',
        orderNumber: 'caauU',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '54.1',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'caawe',
    },
    {
        itemNumber: '173',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '116',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-8,088',
        orderNumber: 'caauE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caawK',
    },
    {
        itemNumber: '174',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-2,022',
        orderNumber: 'caavD',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caawK',
    },
    {
        itemNumber: '175',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '54.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'caawZ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaw0',
    },
    {
        itemNumber: '176',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '4,890',
        orderNumber: 'caawK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaxj',
    },
    {
        itemNumber: '177',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '117.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaxj',
    },
    {
        itemNumber: '178',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxj',
    },
    {
        itemNumber: '179',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxj',
    },
    {
        itemNumber: '180',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '181',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '117.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '182',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '183',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '184',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '185',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaxO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '186',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caaxO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '117.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaxQ',
    },
    {
        itemNumber: '187',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.8',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-2,080',
        orderNumber: 'caaxw',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caax9',
    },
    {
        itemNumber: '188',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.9',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-1,080',
        orderNumber: 'caaxx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaya',
    },
    {
        itemNumber: '189',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caaxO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayn',
    },
    {
        itemNumber: '190',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caaxP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayn',
    },
    {
        itemNumber: '191',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caaxP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayo',
    },
    {
        itemNumber: '192',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caayo',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayw',
    },
    {
        itemNumber: '193',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caayx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayF',
    },
    {
        itemNumber: '194',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caayG',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '117.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caayP',
    },
    {
        itemNumber: '195',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caayQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaA1',
    },
    {
        itemNumber: '196',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caayQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaA1',
    },
    {
        itemNumber: '197',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '117.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caayQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaA1',
    },
    {
        itemNumber: '198',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caayQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaA1',
    },
    {
        itemNumber: '199',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '117',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caaAx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaA2',
    },
    {
        itemNumber: '200',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caaAx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaA2',
    },
    {
        itemNumber: '201',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caazW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaBw',
    },
    {
        itemNumber: '202',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '55.4',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '3,136',
        orderNumber: 'caazW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'caaBw',
    },
    {
        itemNumber: '203',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaz1',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaBv',
    },
    {
        itemNumber: '204',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '55.4',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '3,136',
        orderNumber: 'caaz1',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '55',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'caaBv',
    },
    {
        itemNumber: '205',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '9,890',
        orderNumber: 'caaAH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaCt',
    },
    {
        itemNumber: '206',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '9,890',
        orderNumber: 'caaAJ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaCt',
    },
    {
        itemNumber: '207',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '117',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '7,912',
        orderNumber: 'caaAK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaCT',
    },
    {
        itemNumber: '208',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaAK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaCT',
    },
    {
        itemNumber: '209',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '117',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '9,890',
        orderNumber: 'caaA5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaCT',
    },
    {
        itemNumber: '210',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '55.4',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '2,952',
        orderNumber: 'caaAp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'caaCY',
    },
    {
        itemNumber: '211',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '55.4',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '1,968',
        orderNumber: 'caaAp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaCY',
    },
    {
        itemNumber: '212',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaBa',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaCZ',
    },
    {
        itemNumber: '213',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaBa',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaCZ',
    },
    {
        itemNumber: '214',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaBa',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaCZ',
    },
    {
        itemNumber: '215',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '55.3',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '1,568',
        orderNumber: 'caaBa',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '54.9',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaCZ',
    },
    {
        itemNumber: '216',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caaCW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaEc',
    },
    {
        itemNumber: '217',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-3,066',
        orderNumber: 'caaCW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaEc',
    },
    {
        itemNumber: '218',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caaCW',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaEc',
    },
    {
        itemNumber: '219',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaEx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaES',
    },
    {
        itemNumber: '220',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '257.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caaDk',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '257.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaEu',
    },
    {
        itemNumber: '221',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '257.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '-32',
        orderNumber: 'caaDl',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '257.5',
        transactionFees: '6',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaEu',
    },
    {
        itemNumber: '222',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaEx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaES',
    },
    {
        itemNumber: '223',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaEx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaES',
    },
    {
        itemNumber: '224',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaEx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaES',
    },
    {
        itemNumber: '225',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaEx',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaES',
    },
    {
        itemNumber: '226',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caaE4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaFW',
    },
    {
        itemNumber: '227',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caaE4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaFW',
    },
    {
        itemNumber: '228',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caaE4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaFW',
    },
    {
        itemNumber: '229',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'caaE4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaFW',
    },
    {
        itemNumber: '230',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'caaE6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaFX',
    },
    {
        itemNumber: '231',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'caaE6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaFX',
    },
    {
        itemNumber: '232',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'caaE6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DSF宏股份有限公司',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '47.8',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'caaFX',
    },
    {
        itemNumber: '233',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaEC',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGu',
    },
    {
        itemNumber: '234',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaEC',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGu',
    },
    {
        itemNumber: '235',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaEC',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGu',
    },
    {
        itemNumber: '236',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaEC',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGu',
    },
    {
        itemNumber: '237',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caaEC',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGu',
    },
    {
        itemNumber: '238',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaF2',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGw',
    },
    {
        itemNumber: '239',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaF2',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGw',
    },
    {
        itemNumber: '240',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '1,956',
        orderNumber: 'caaF2',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaGw',
    },
    {
        itemNumber: '241',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '978',
        orderNumber: 'caaF2',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaGw',
    },
    {
        itemNumber: '242',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '9,890',
        orderNumber: 'caaF3',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaHs',
    },
    {
        itemNumber: '243',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '3,956',
        orderNumber: 'caaF4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaHs',
    },
    {
        itemNumber: '244',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '5,934',
        orderNumber: 'caaF4',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaHs',
    },
    {
        itemNumber: '245',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '116',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '5,934',
        orderNumber: 'caaF5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaHs',
    },
    {
        itemNumber: '246',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '116',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '3,956',
        orderNumber: 'caaF5',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaHs',
    },
    {
        itemNumber: '247',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caaF6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaHZ',
    },
    {
        itemNumber: '248',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-5,110',
        orderNumber: 'caaF8',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaKh',
    },
    {
        itemNumber: '249',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caaKG',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caaKH',
    },
    {
        itemNumber: '250',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '376',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-2,042',
        orderNumber: 'caaM2',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '375',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaNa',
    },
    {
        itemNumber: '251',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '257',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-64',
        orderNumber: 'caaNO',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '257',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaO7',
    },
    {
        itemNumber: '252',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '116.5',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-4,088',
        orderNumber: 'caaNN',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaPa',
    },
    {
        itemNumber: '253',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caaNN',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaPa',
    },
    {
        itemNumber: '254',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '257',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '1,936',
        orderNumber: 'caaO8',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '257.5',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaPs',
    },
    {
        itemNumber: '255',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '258',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-2,064',
        orderNumber: 'caaPc',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '257.5',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaPs',
    },
    {
        itemNumber: '256',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caaPd',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaPA',
    },
    {
        itemNumber: '257',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caaPd',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '117',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caaPA',
    },
    {
        itemNumber: '258',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaPd',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '117',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaPz',
    },
    {
        itemNumber: '259',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '375.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '3,958',
        orderNumber: 'caaOp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '377.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaQi',
    },
    {
        itemNumber: '260',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '376',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-1,042',
        orderNumber: 'caaM3',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '375.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaQy',
    },
    {
        itemNumber: '261',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '295',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'caaQA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '295',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caaQC',
    },
    {
        itemNumber: '262',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '295',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'caaQE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '295',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caaRW',
    },
    {
        itemNumber: '263',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '296',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-2,036',
        orderNumber: 'caaRa',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '295',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caaRW',
    },
    {
        itemNumber: '264',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '378.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-2,042',
        orderNumber: 'caaRS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '379.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaS2',
    },
    {
        itemNumber: '265',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '375.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-42',
        orderNumber: 'caaQS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '375.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaTb',
    },
    {
        itemNumber: '266',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-1,416',
        orderNumber: 'caaRi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaUy',
    },
    {
        itemNumber: '267',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-1,416',
        orderNumber: 'caaRi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaUy',
    },
    {
        itemNumber: '268',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-1,416',
        orderNumber: 'caaRi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaUy',
    },
    {
        itemNumber: '269',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-1,416',
        orderNumber: 'caaRi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaUy',
    },
    {
        itemNumber: '270',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-1,416',
        orderNumber: 'caaRi',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.4',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caaUy',
    },
    {
        itemNumber: '271',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '256',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-64',
        orderNumber: 'caaWD',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '256',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaWJ',
    },
    {
        itemNumber: '272',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '256',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '-64',
        orderNumber: 'caaWE',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '256',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaWJ',
    },
    {
        itemNumber: '273',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '373.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-1,042',
        orderNumber: 'caaWm',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '373',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caaXj',
    },
    {
        itemNumber: '274',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '116',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-88',
        orderNumber: 'caaYk',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caaZA',
    },
    {
        itemNumber: '275',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaYk',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZA',
    },
    {
        itemNumber: '276',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaZB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZC',
    },
    {
        itemNumber: '277',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaZB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZC',
    },
    {
        itemNumber: '278',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaZB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZC',
    },
    {
        itemNumber: '279',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaZB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZC',
    },
    {
        itemNumber: '280',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caaZB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caaZC',
    },
    {
        itemNumber: '281',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '370',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-1,042',
        orderNumber: 'caaX1',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '369.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caa0x',
    },
    {
        itemNumber: '282',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaZP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caa0R',
    },
    {
        itemNumber: '283',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '54.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'caaZP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '54.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'caa0R',
    },
    {
        itemNumber: '284',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '54.9',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '2,352',
        orderNumber: 'caaZP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '54.5',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'caa0R',
    },
    {
        itemNumber: '285',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caa0l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1B',
    },
    {
        itemNumber: '286',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caa0l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1B',
    },
    {
        itemNumber: '287',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caa0l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1B',
    },
    {
        itemNumber: '288',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caa0l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1B',
    },
    {
        itemNumber: '289',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '116',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '1,978',
        orderNumber: 'caa0l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1B',
    },
    {
        itemNumber: '290',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa0S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1C',
    },
    {
        itemNumber: '291',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa0S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa1C',
    },
    {
        itemNumber: '292',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa0S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa1C',
    },
    {
        itemNumber: '293',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '115',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-88',
        orderNumber: 'caa1a',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caa1E',
    },
    {
        itemNumber: '294',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1a',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1H',
    },
    {
        itemNumber: '295',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '115',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-66',
        orderNumber: 'caa1b',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caa1H',
    },
    {
        itemNumber: '296',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1b',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1H',
    },
    {
        itemNumber: '297',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1b',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa1E',
    },
    {
        itemNumber: '298',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '253.5',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '5,936',
        orderNumber: 'caa0B',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '252',
        transactionFees: '12',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caa1I',
    },
    {
        itemNumber: '299',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '115',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caa1N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caa1P',
    },
    {
        itemNumber: '300',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa10',
    },
    {
        itemNumber: '301',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa10',
    },
    {
        itemNumber: '302',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa10',
    },
    {
        itemNumber: '303',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa1S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa10',
    },
    {
        itemNumber: '304',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caa1S',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa2j',
    },
    {
        itemNumber: '305',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-1,022',
        orderNumber: 'caa2g',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa2j',
    },
    {
        itemNumber: '306',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '114.5',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '-3,066',
        orderNumber: 'caa2j',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '18',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caa2K',
    },
    {
        itemNumber: '307',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '294',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-14,035',
        orderNumber: 'caaV7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '287',
        transactionFees: '6',
        transactionTax: '11',
        netProfit: '',
        orderNumber: 'caa32',
    },
    {
        itemNumber: '308',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa36',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa38',
    },
    {
        itemNumber: '309',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa36',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa38',
    },
    {
        itemNumber: '310',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa36',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa38',
    },
    {
        itemNumber: '311',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '114',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caa4d',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caa4n',
    },
    {
        itemNumber: '312',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa4q',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa4T',
    },
    {
        itemNumber: '313',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa4q',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa4T',
    },
    {
        itemNumber: '314',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa4q',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa4T',
    },
    {
        itemNumber: '315',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa4q',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa44',
    },
    {
        itemNumber: '316',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa41',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa44',
    },
    {
        itemNumber: '317',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa41',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa44',
    },
    {
        itemNumber: '318',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa41',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa44',
    },
    {
        itemNumber: '319',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa42',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa49',
    },
    {
        itemNumber: '320',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa42',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa49',
    },
    {
        itemNumber: '321',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa42',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '114',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa49',
    },
    {
        itemNumber: '322',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '-44',
        orderNumber: 'caa49',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '114',
        transactionFees: '12',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa5k',
    },
    {
        itemNumber: '323',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '115',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '-88',
        orderNumber: 'caa3K',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '24',
        transactionTax: '20',
        netProfit: '',
        orderNumber: 'caa5L',
    },
    {
        itemNumber: '324',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa3K',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa5L',
    },
    {
        itemNumber: '325',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '115',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'caa5M',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '115',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'caa5P',
    },
    {
        itemNumber: '326',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '368.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '958',
        orderNumber: 'caa0Q',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '369',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'caa5S',
    },
    {
        itemNumber: '327',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.7',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'caa59',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '54.7',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'caa6r',
    },
    {
        itemNumber: '328',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa6N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa6T',
    },
    {
        itemNumber: '329',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa6N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa6T',
    },
    {
        itemNumber: '330',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa6N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa6T',
    },
    {
        itemNumber: '331',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa6N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa6T',
    },
    {
        itemNumber: '332',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '294',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-6,036',
        orderNumber: 'caaV8',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '291',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caa6I',
    },
    {
        itemNumber: '333',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '287.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '6,964',
        orderNumber: 'caa5t',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '291',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caa6I',
    },
    {
        itemNumber: '334',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '287.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '6,964',
        orderNumber: 'caa5u',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '291',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caa6I',
    },
    {
        itemNumber: '335',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '287.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '6,964',
        orderNumber: 'caa5v',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '291',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caa6I',
    },
    {
        itemNumber: '336',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '287.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '6,964',
        orderNumber: 'caa5w',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '291',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'caa6I',
    },
    {
        itemNumber: '337',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '-22',
        orderNumber: 'caa6N',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '115.5',
        transactionFees: '6',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'caa6T',
    },
    {
        itemNumber: '338',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '-46',
        orderNumber: 'caa8K',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '',
        orderNumber: 'caa9d',
    },
    {
        itemNumber: '339',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '-46',
        orderNumber: 'caa8L',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '',
        orderNumber: 'caa9d',
    },
    {
        itemNumber: '340',
        tradeDate: '2024/1/22',
        symbol: 'IQF文曄期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '156.5',
        transactionFees: '6',
        transactionTax: '6',
        netProfit: '-24',
        orderNumber: 'caa97',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'IQF文曄期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '156.5',
        transactionFees: '6',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'cabaz',
    },
    {
        itemNumber: '341',
        tradeDate: '2024/1/22',
        symbol: 'IQF文曄期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '156.5',
        transactionFees: '6',
        transactionTax: '6',
        netProfit: '-24',
        orderNumber: 'caa97',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'IQF文曄期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '156.5',
        transactionFees: '6',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'cabaz',
    },
    {
        itemNumber: '342',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '54.7',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-10,080',
        orderNumber: 'caa7l',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '55.7',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabbj',
    },
    {
        itemNumber: '343',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-1,080',
        orderNumber: 'cabbH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '55.9',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabbK',
    },
    {
        itemNumber: '344',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabbM',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.1',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabbN',
    },
    {
        itemNumber: '345',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabbP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabbT',
    },
    {
        itemNumber: '346',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabbP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabbT',
    },
    {
        itemNumber: '347',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabbP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabbT',
    },
    {
        itemNumber: '348',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabbP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabbT',
    },
    {
        itemNumber: '349',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabbP',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabbT',
    },
    {
        itemNumber: '350',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.3',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabbU',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabbY',
    },
    {
        itemNumber: '351',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.3',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabb6',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabcg',
    },
    {
        itemNumber: '352',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabb7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcg',
    },
    {
        itemNumber: '353',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56.3',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'cabb7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabcg',
    },
    {
        itemNumber: '354',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabb7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcg',
    },
    {
        itemNumber: '355',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabb7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcg',
    },
    {
        itemNumber: '356',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabch',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcj',
    },
    {
        itemNumber: '357',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabch',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcj',
    },
    {
        itemNumber: '358',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabch',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcj',
    },
    {
        itemNumber: '359',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabch',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcj',
    },
    {
        itemNumber: '360',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabch',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcj',
    },
    {
        itemNumber: '361',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '-48',
        orderNumber: 'cabcp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '56.3',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'cabcr',
    },
    {
        itemNumber: '362',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabcp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcr',
    },
    {
        itemNumber: '363',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabcp',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.3',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabcr',
    },
    {
        itemNumber: '364',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.5',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '920',
        orderNumber: 'cabcG',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.6',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabc8',
    },
    {
        itemNumber: '365',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabcH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.6',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabc9',
    },
    {
        itemNumber: '366',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56.5',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '368',
        orderNumber: 'cabcH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56.6',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabc9',
    },
    {
        itemNumber: '367',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabcH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.6',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabc9',
    },
    {
        itemNumber: '368',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.5',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabcH',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.6',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabc9',
    },
    {
        itemNumber: '369',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabdz',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabdG',
    },
    {
        itemNumber: '370',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '380.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '-42',
        orderNumber: 'cabdK',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'RAF奇鋐期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '380.5',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '',
        orderNumber: 'cabdM',
    },
    {
        itemNumber: '371',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '55.9',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabd3',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '55.9',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabem',
    },
    {
        itemNumber: '372',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-1,080',
        orderNumber: 'cabd7',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '55.9',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabem',
    },
    {
        itemNumber: '373',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabee',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabev',
    },
    {
        itemNumber: '374',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'cabee',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabev',
    },
    {
        itemNumber: '375',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'cabee',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabev',
    },
    {
        itemNumber: '376',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '254.5',
        transactionFees: '24',
        transactionTax: '40',
        netProfit: '-128',
        orderNumber: 'cabeB',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DKF廣達電腦股份有限',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '254.5',
        transactionFees: '24',
        transactionTax: '40',
        netProfit: '',
        orderNumber: 'cabfq',
    },
    {
        itemNumber: '377',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabex',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabfx',
    },
    {
        itemNumber: '378',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabex',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabfx',
    },
    {
        itemNumber: '379',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabex',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabfx',
    },
    {
        itemNumber: '380',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabex',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabfx',
    },
    {
        itemNumber: '381',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabex',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabfx',
    },
    {
        itemNumber: '382',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '387',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '957',
        orderNumber: 'cabfI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '387.5',
        transactionFees: '6',
        transactionTax: '16',
        netProfit: '',
        orderNumber: 'cabfM',
    },
    {
        itemNumber: '383',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '387',
        transactionFees: '6',
        transactionTax: '15',
        netProfit: '957',
        orderNumber: 'cabfJ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PTF雙鴻期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '387.5',
        transactionFees: '6',
        transactionTax: '16',
        netProfit: '',
        orderNumber: 'cabfM',
    },
    {
        itemNumber: '384',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '55.8',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '784',
        orderNumber: 'cabeR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabf7',
    },
    {
        itemNumber: '385',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '55.8',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '3,136',
        orderNumber: 'cabeR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '56.2',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'cabf7',
    },
    {
        itemNumber: '386',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '-32',
        orderNumber: 'cabfS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56.2',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabf6',
    },
    {
        itemNumber: '387',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabfS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabf6',
    },
    {
        itemNumber: '388',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabfS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabf6',
    },
    {
        itemNumber: '389',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabfS',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabf6',
    },
    {
        itemNumber: '390',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'cabcv',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'cabgB',
    },
    {
        itemNumber: '391',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '-110',
        orderNumber: 'cabcz',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'DXF緯創資通股份有限',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '116.5',
        transactionFees: '30',
        transactionTax: '25',
        netProfit: '',
        orderNumber: 'cabgC',
    },
    {
        itemNumber: '392',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '384',
        orderNumber: 'cabhQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabiD',
    },
    {
        itemNumber: '393',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '384',
        orderNumber: 'cabhQ',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabiT',
    },
    {
        itemNumber: '394',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '768',
        orderNumber: 'cabhR',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56.2',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabiT',
    },
    {
        itemNumber: '395',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '368',
        orderNumber: 'cabiA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '56.2',
        transactionFees: '12',
        transactionTax: '4',
        netProfit: '',
        orderNumber: 'cabiT',
    },
    {
        itemNumber: '396',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabiA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabiU',
    },
    {
        itemNumber: '397',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabiA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabiU',
    },
    {
        itemNumber: '398',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '184',
        orderNumber: 'cabiA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabjH',
    },
    {
        itemNumber: '399',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '56.1',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '736',
        orderNumber: 'cabiA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '56.2',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'cabjH',
    },
    {
        itemNumber: '400',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '-80',
        orderNumber: 'cabjA',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '56.2',
        transactionFees: '30',
        transactionTax: '10',
        netProfit: '',
        orderNumber: 'cabj5',
    },
    {
        itemNumber: '401',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '4,954',
        orderNumber: 'cabkg',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '433.5',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '',
        orderNumber: 'cabki',
    },
    {
        itemNumber: '402',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '436',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '4,954',
        orderNumber: 'cabkh',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '433.5',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '',
        orderNumber: 'cabki',
    },
    {
        itemNumber: '403',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-16',
        orderNumber: 'cabjI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabj8',
    },
    {
        itemNumber: '404',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'cabjI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cablx',
    },
    {
        itemNumber: '405',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'cabjI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cablx',
    },
    {
        itemNumber: '406',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-216',
        orderNumber: 'cabjI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '56.1',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cablx',
    },
    {
        itemNumber: '407',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-616',
        orderNumber: 'cabjI',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabmb',
    },
    {
        itemNumber: '408',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-616',
        orderNumber: 'cabky',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabmb',
    },
    {
        itemNumber: '409',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '4',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '-2,464',
        orderNumber: 'cabky',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '4',
        dealPrice: '55.9',
        transactionFees: '24',
        transactionTax: '8',
        netProfit: '',
        orderNumber: 'cabmb',
    },
    {
        itemNumber: '410',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '3',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '-1,848',
        orderNumber: 'cabkz',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '3',
        dealPrice: '55.9',
        transactionFees: '18',
        transactionTax: '6',
        netProfit: '',
        orderNumber: 'cabmb',
    },
    {
        itemNumber: '411',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '56.2',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '-616',
        orderNumber: 'cabkz',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'LQF英業達期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '55.9',
        transactionFees: '6',
        transactionTax: '2',
        netProfit: '',
        orderNumber: 'cabmb',
    },
    {
        itemNumber: '412',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '434.5',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '-46',
        orderNumber: 'cabmd',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'PJF台光電期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '434.5',
        transactionFees: '6',
        transactionTax: '17',
        netProfit: '',
        orderNumber: 'cabmg',
    },
    {
        itemNumber: '413',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '295.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'cabcD',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '295.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'cabdp',
    },
    {
        itemNumber: '414',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '296.5',
        transactionFees: '12',
        transactionTax: '24',
        netProfit: '-72',
        orderNumber: 'cabnl',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '296.5',
        transactionFees: '12',
        transactionTax: '24',
        netProfit: '',
        orderNumber: 'cabno',
    },
    {
        itemNumber: '415',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '296.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'cabnm',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '296.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'cabnp',
    },
    {
        itemNumber: '416',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '296.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'cabnm',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '296.5',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'cabnp',
    },
    {
        itemNumber: '417',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '2',
        shortQuantity: '',
        dealPrice: '296.5',
        transactionFees: '12',
        transactionTax: '24',
        netProfit: '-2,072',
        orderNumber: 'cabnn',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '2',
        dealPrice: '296',
        transactionFees: '12',
        transactionTax: '24',
        netProfit: '',
        orderNumber: 'cabnu',
    },
    {
        itemNumber: '418',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '627',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '-70',
        orderNumber: 'cabgu',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '627',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'cabhJ',
    },
    {
        itemNumber: '419',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '5',
        shortQuantity: '',
        dealPrice: '627',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '-70',
        orderNumber: 'cabgv',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'QFF小型台積電期貨',
        longQuantity: '',
        shortQuantity: '5',
        dealPrice: '627',
        transactionFees: '30',
        transactionTax: '5',
        netProfit: '',
        orderNumber: 'cabhI',
    },
    {
        itemNumber: '420',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '297',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'cabof',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '297',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'cabog',
    },
    {
        itemNumber: '421',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '1',
        shortQuantity: '',
        dealPrice: '297',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '-36',
        orderNumber: 'cabom',
    },
    {
        itemNumber: '',
        tradeDate: '2024/1/22',
        symbol: 'GHF技嘉期貨',
        longQuantity: '',
        shortQuantity: '1',
        dealPrice: '297',
        transactionFees: '6',
        transactionTax: '12',
        netProfit: '',
        orderNumber: 'cabpe',
    },
    {
        itemNumber: '',
        tradeDate: '',
        symbol: '台幣小計',
        longQuantity: '838',
        shortQuantity: '838',
        dealPrice: '',
        transactionFees: '10,056',
        transactionTax: '7,726',
        netProfit: '608,458',
        orderNumber: '',
    },
    {
        itemNumber: '',
        tradeDate: '',
        symbol: '',
        longQuantity: '',
        shortQuantity: '',
        dealPrice: '',
        transactionFees: '',
        transactionTax: '',
        netProfit: '',
        orderNumber: '',
    },
];
const data2 = [
    {
        symbol: 'GHF技嘉期貨03',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:27:34',
        orderNumber: 'cabpe',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:25:50',
        orderNumber: 'cabo8',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:25:12',
        orderNumber: 'cabo1',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:22:54',
        orderNumber: 'caboG',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:20:42',
        orderNumber: 'cabos',
    },
    {
        symbol: 'GHF技嘉期貨03',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:20:33',
        orderNumber: 'cabon',
    },
    {
        symbol: 'GHF技嘉期貨03',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:20:33',
        orderNumber: 'cabom',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:20:05',
        orderNumber: 'cabol',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:19:28',
        orderNumber: 'cabog',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:18:40',
        orderNumber: 'cabof',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:15:58',
        orderNumber: 'cabnQ',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:12:20',
        orderNumber: 'cabnu',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:12:03',
        orderNumber: 'cabnp',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:12:01',
        orderNumber: 'cabno',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:11:59',
        orderNumber: 'cabnn',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:11:58',
        orderNumber: 'cabnm',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:11:58',
        orderNumber: 'cabnl',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:09:18',
        orderNumber: 'cabmW',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:05:12',
        orderNumber: 'cabmg',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:04:50',
        orderNumber: 'cabmd',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:04:24',
        orderNumber: 'cabmb',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:02:49',
        orderNumber: 'cablx',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:00:03',
        orderNumber: 'cabkz',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '13:00:02',
        orderNumber: 'cabky',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:59:58',
        orderNumber: 'cabkx',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:58:12',
        orderNumber: 'cabki',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:58:11',
        orderNumber: 'cabkh',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:58:11',
        orderNumber: 'cabkg',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:58:31',
        orderNumber: 'cabj8',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:56:53',
        orderNumber: 'cabj5',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:54:05',
        orderNumber: 'cabjI',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:54:04',
        orderNumber: 'cabjH',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:53:10',
        orderNumber: 'cabjA',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:48:11',
        orderNumber: 'cabiU',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:48:07',
        orderNumber: 'cabiT',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:47:37',
        orderNumber: 'cabiD',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:47:20',
        orderNumber: 'cabiA',
    },
    {
        symbol: 'CYF南亞科期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:44:19',
        orderNumber: 'cabic',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:41:26',
        orderNumber: 'cabhR',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:41:26',
        orderNumber: 'cabhQ',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:12:33',
        orderNumber: 'cabhJ',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:12:33',
        orderNumber: 'cabhI',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:33:37',
        orderNumber: 'cabgC',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:33:37',
        orderNumber: 'cabgB',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:36:30',
        orderNumber: 'cabgv',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:36:30',
        orderNumber: 'cabgu',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:30:48',
        orderNumber: 'cabf7',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:30:48',
        orderNumber: 'cabf6',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:29:10',
        orderNumber: 'cabfS',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:27:26',
        orderNumber: 'cabfM',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:27:16',
        orderNumber: 'cabfJ',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:27:16',
        orderNumber: 'cabfI',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:26:23',
        orderNumber: 'cabfx',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:24:23',
        orderNumber: 'cabfq',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:22:10',
        orderNumber: 'cabeR',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:20:11',
        orderNumber: 'cabeB',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:19:15',
        orderNumber: 'cabex',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:18:54',
        orderNumber: 'cabev',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:18:11',
        orderNumber: 'cabem',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:17:12',
        orderNumber: 'cabee',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:16:11',
        orderNumber: 'cabd7',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:15:37',
        orderNumber: 'cabd3',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:13:42',
        orderNumber: 'cabdM',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:13:29',
        orderNumber: 'cabdK',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:13:28',
        orderNumber: 'cabdG',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:13:00',
        orderNumber: 'cabdz',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '13:11:12',
        orderNumber: 'cabdp',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:11:27',
        orderNumber: 'cabc9',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:11:22',
        orderNumber: 'cabc8',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:10:30',
        orderNumber: 'cabcR',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:10:02',
        orderNumber: 'cabcH',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:10:02',
        orderNumber: 'cabcG',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:10:27',
        orderNumber: 'cabcD',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:57',
        orderNumber: 'cabcA',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:57',
        orderNumber: 'cabcz',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:55',
        orderNumber: 'cabcv',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:52',
        orderNumber: 'cabcr',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:39',
        orderNumber: 'cabcp',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:09:10',
        orderNumber: 'cabcj',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:08:25',
        orderNumber: 'cabch',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:08:20',
        orderNumber: 'cabcg',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:08:06',
        orderNumber: 'cabb7',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:08:00',
        orderNumber: 'cabb6',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:07:32',
        orderNumber: 'cabbY',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:07:28',
        orderNumber: 'cabbU',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:07:25',
        orderNumber: 'cabbT',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:07:15',
        orderNumber: 'cabbP',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:07:08',
        orderNumber: 'cabbN',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:06:48',
        orderNumber: 'cabbM',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '12:06:28',
        orderNumber: 'cabbK',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:06:06',
        orderNumber: 'cabbH',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '12:03:43',
        orderNumber: 'cabbj',
    },
    {
        symbol: 'IQF文曄期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:58:37',
        orderNumber: 'cabaz',
    },
    {
        symbol: 'IQF文曄期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:54:10',
        orderNumber: 'caa97',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:45:13',
        orderNumber: 'caa9d',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:45:04',
        orderNumber: 'caa8L',
    },
    {
        symbol: 'PJF台光電期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:38:39',
        orderNumber: 'caa8K',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:30:25',
        orderNumber: 'caa7l',
    },
    {
        symbol: 'PNF小型祥碩期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:28:52',
        orderNumber: 'caa7b',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:30:21',
        orderNumber: 'caa6T',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:25:36',
        orderNumber: 'caa6N',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:27:03',
        orderNumber: 'caa6I',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:23:39',
        orderNumber: 'caa6r',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:23:33',
        orderNumber: 'caa59',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:19:56',
        orderNumber: 'caa5S',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:19:43',
        orderNumber: 'caa5P',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:19:41',
        orderNumber: 'caa5M',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:19:37',
        orderNumber: 'caa5L',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:17:30',
        orderNumber: 'caa5w',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:17:30',
        orderNumber: 'caa5v',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:17:30',
        orderNumber: 'caa5u',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:17:30',
        orderNumber: 'caa5t',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:15:54',
        orderNumber: 'caa5k',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:14:53',
        orderNumber: 'caa49',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:14:52',
        orderNumber: 'caa44',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:14:28',
        orderNumber: 'caa42',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:14:28',
        orderNumber: 'caa41',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:14:07',
        orderNumber: 'caa4T',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:12:14',
        orderNumber: 'caa4q',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:11:10',
        orderNumber: 'caa4n',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:11:07',
        orderNumber: 'caa4d',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:10:02',
        orderNumber: 'caa38',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:09:43',
        orderNumber: 'caa36',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:09:22',
        orderNumber: 'caa32',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:18:59',
        orderNumber: 'caa3K',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '11:02:44',
        orderNumber: 'caa2K',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '11:00:29',
        orderNumber: 'caa2j',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:59:51',
        orderNumber: 'caa2g',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:59:47',
        orderNumber: 'caa10',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:44',
        orderNumber: 'caa1S',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:33',
        orderNumber: 'caa1P',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:31',
        orderNumber: 'caa1N',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:30',
        orderNumber: 'caa1I',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:29',
        orderNumber: 'caa1H',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:29',
        orderNumber: 'caa1E',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:58:06',
        orderNumber: 'caa1C',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:57:53',
        orderNumber: 'caa1B',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:57:02',
        orderNumber: 'caa1b',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:57:01',
        orderNumber: 'caa1a',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:55:28',
        orderNumber: 'caa0S',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:55:27',
        orderNumber: 'caa0R',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:54:16',
        orderNumber: 'caa0Q',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:52:01',
        orderNumber: 'caa0B',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:51:35',
        orderNumber: 'caa0x',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:50:38',
        orderNumber: 'caa0l',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:48:15',
        orderNumber: 'caaZP',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:46:34',
        orderNumber: 'caaZC',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:44:45',
        orderNumber: 'caaZB',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:44:31',
        orderNumber: 'caaZA',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:34:53',
        orderNumber: 'caaYk',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:35:02',
        orderNumber: 'caaX1',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:31:26',
        orderNumber: 'caaXj',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:29:13',
        orderNumber: 'caaWJ',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:28:48',
        orderNumber: 'caaWE',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:28:48',
        orderNumber: 'caaWD',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:27:18',
        orderNumber: 'caaWm',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:26:43',
        orderNumber: 'caaV8',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:26:36',
        orderNumber: 'caaV7',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:21:22',
        orderNumber: 'caaUy',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:18:04',
        orderNumber: 'caaTb',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:17:29',
        orderNumber: 'caaS2',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:14:36',
        orderNumber: 'caaRW',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:14:21',
        orderNumber: 'caaRS',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:13:07',
        orderNumber: 'caaRi',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:12:54',
        orderNumber: 'caaRa',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:14:38',
        orderNumber: 'caaQS',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:11:36',
        orderNumber: 'caaQE',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:11:12',
        orderNumber: 'caaQC',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:11:03',
        orderNumber: 'caaQA',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:10:48',
        orderNumber: 'caaQy',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:10:43',
        orderNumber: 'caaQi',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:09:45',
        orderNumber: 'caaPA',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:09:45',
        orderNumber: 'caaPz',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:08:55',
        orderNumber: 'caaPs',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:08:31',
        orderNumber: 'caaPd',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:08:15',
        orderNumber: 'caaPc',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:08:13',
        orderNumber: 'caaPa',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:07:50',
        orderNumber: 'caaO8',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:07:50',
        orderNumber: 'caaO7',
    },
    {
        symbol: 'RAF奇鋐期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:04:41',
        orderNumber: 'caaOp',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:02:38',
        orderNumber: 'caaNO',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:02:36',
        orderNumber: 'caaNN',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '10:01:20',
        orderNumber: 'caaNa',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:01:10',
        orderNumber: 'caaM3',
    },
    {
        symbol: 'PTF雙鴻期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '10:01:10',
        orderNumber: 'caaM2',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:52:13',
        orderNumber: 'caaKH',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:52:11',
        orderNumber: 'caaKG',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:52:10',
        orderNumber: 'caaKh',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:44:36',
        orderNumber: 'caaHZ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:42:36',
        orderNumber: 'caaHs',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:56',
        orderNumber: 'caaGw',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:55',
        orderNumber: 'caaGu',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF8',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF6',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF5',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF4',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF3',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:33',
        orderNumber: 'caaF2',
    },
    {
        symbol: 'DSF宏碁股份有限公司02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:27',
        orderNumber: 'caaFX',
    },
    {
        symbol: 'DSF宏碁股份有限公司02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:40:27',
        orderNumber: 'caaFW',
    },
    {
        symbol: 'DSF宏碁股份有限公司02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:39:53',
        orderNumber: 'caaE6',
    },
    {
        symbol: 'DSF宏碁股份有限公司02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:39:53',
        orderNumber: 'caaE4',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:38:26',
        orderNumber: 'caaES',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:37:49',
        orderNumber: 'caaEC',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:37:48',
        orderNumber: 'caaEx',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:38:09',
        orderNumber: 'caaEu',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:36:26',
        orderNumber: 'caaEc',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:35:25',
        orderNumber: 'caaDl',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:35:24',
        orderNumber: 'caaDk',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:34:46',
        orderNumber: 'caaCZ',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:34:46',
        orderNumber: 'caaCY',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:34:19',
        orderNumber: 'caaCW',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:34:01',
        orderNumber: 'caaCT',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:32:47',
        orderNumber: 'caaCt',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:30:16',
        orderNumber: 'caaBw',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:30:16',
        orderNumber: 'caaBv',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:29:40',
        orderNumber: 'caaBa',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:29:42',
        orderNumber: 'caaA5',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:29:32',
        orderNumber: 'caaA2',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:29:32',
        orderNumber: 'caaA1',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:28:39',
        orderNumber: 'caaAK',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:28:38',
        orderNumber: 'caaAJ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:28:37',
        orderNumber: 'caaAH',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:28:22',
        orderNumber: 'caaAx',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:28:40',
        orderNumber: 'caaAp',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:27:25',
        orderNumber: 'caaz1',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:27:01',
        orderNumber: 'caazW',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:49',
        orderNumber: 'caayQ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:41',
        orderNumber: 'caayP',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:37',
        orderNumber: 'caayG',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:16',
        orderNumber: 'caayF',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:14',
        orderNumber: 'caayx',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:23:01',
        orderNumber: 'caayw',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:54',
        orderNumber: 'caayo',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:54',
        orderNumber: 'caayn',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:24',
        orderNumber: 'caaya',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:24',
        orderNumber: 'caax9',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:21:38',
        orderNumber: 'caaxQ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:21:30',
        orderNumber: 'caaxP',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:21:30',
        orderNumber: 'caaxO',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:10',
        orderNumber: 'caaxx',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:22:06',
        orderNumber: 'caaxw',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:20:30',
        orderNumber: 'caaxj',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:19:49',
        orderNumber: 'caaxi',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:18:54',
        orderNumber: 'caaw0',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:18:53',
        orderNumber: 'caawZ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:18:20',
        orderNumber: 'caawK',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:16:55',
        orderNumber: 'caawe',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:16:29',
        orderNumber: 'caavV',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:15:59',
        orderNumber: 'caavD',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:15:57',
        orderNumber: 'caavt',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:15:42',
        orderNumber: 'caavg',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:14:34',
        orderNumber: 'caauW',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:14:50',
        orderNumber: 'caauU',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:14:50',
        orderNumber: 'caauT',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:14:16',
        orderNumber: 'caauE',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:13:19',
        orderNumber: 'caaut',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:12:34',
        orderNumber: 'caauq',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:12:17',
        orderNumber: 'caaue',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:11:59',
        orderNumber: 'caaua',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:11:13',
        orderNumber: 'caas5',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:10:35',
        orderNumber: 'caas0',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:11:55',
        orderNumber: 'caasZ',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:10:24',
        orderNumber: 'caasw',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:09:12',
        orderNumber: 'caasm',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:09:24',
        orderNumber: 'caask',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:09:00',
        orderNumber: 'caasb',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:08:39',
        orderNumber: 'caar9',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:08:36',
        orderNumber: 'caar4',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:08:26',
        orderNumber: 'caarW',
    },
    {
        symbol: 'LQF英業達期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:08:54',
        orderNumber: 'caarT',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:08:02',
        orderNumber: 'caarH',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:07:29',
        orderNumber: 'caaqM',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:07:00',
        orderNumber: 'caaqK',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:58',
        orderNumber: 'caaqv',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:33',
        orderNumber: 'caaqo',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:33',
        orderNumber: 'caaqn',
    },
    {
        symbol: 'DQF群創光電股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:02',
        orderNumber: 'caap3',
    },
    {
        symbol: 'DQF群創光電股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:02',
        orderNumber: 'caap2',
    },
    {
        symbol: 'DQF群創光電股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:01',
        orderNumber: 'caap0',
    },
    {
        symbol: 'DQF群創光電股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:06:01',
        orderNumber: 'caapZ',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:57',
        orderNumber: 'caapU',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:55',
        orderNumber: 'caapR',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:52',
        orderNumber: 'caapK',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:37',
        orderNumber: 'caapI',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:35',
        orderNumber: 'caapE',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:32',
        orderNumber: 'caapB',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:32',
        orderNumber: 'caapA',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:20',
        orderNumber: 'caapu',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:09',
        orderNumber: 'caapc',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:03',
        orderNumber: 'caao9',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:03',
        orderNumber: 'caao8',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:05:00',
        orderNumber: 'caao6',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:04:50',
        orderNumber: 'caaoY',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:04:12',
        orderNumber: 'caaom',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:03:16',
        orderNumber: 'caanO',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:03:12',
        orderNumber: 'caanK',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:03:31',
        orderNumber: 'caanD',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:02:50',
        orderNumber: 'caanl',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:02:50',
        orderNumber: 'caanf',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:03:04',
        orderNumber: 'caamA',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:57',
        orderNumber: 'caamy',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:49',
        orderNumber: 'caamv',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:16',
        orderNumber: 'caal5',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:15',
        orderNumber: 'caal1',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:10',
        orderNumber: 'caalX',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:08',
        orderNumber: 'caalW',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:05',
        orderNumber: 'caalR',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:01:06',
        orderNumber: 'caalJ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:54',
        orderNumber: 'caalG',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:49',
        orderNumber: 'caalt',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:36',
        orderNumber: 'caalo',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:33',
        orderNumber: 'caalj',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:16',
        orderNumber: 'caale',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:17',
        orderNumber: 'caald',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:12',
        orderNumber: 'caalc',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:10',
        orderNumber: 'caala',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:03',
        orderNumber: 'caakZ',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:02',
        orderNumber: 'caakV',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:02',
        orderNumber: 'caakR',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:59:52',
        orderNumber: 'caakM',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '09:00:53',
        orderNumber: 'caakK',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:54:30',
        orderNumber: 'caaiL',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:54:30',
        orderNumber: 'caaiK',
    },
    {
        symbol: 'DXF緯創資通股份有限02/03',
        orderType: '平倉ROD',
        tradingCategory: '賣/買',
        tradeDate: '2024/01/22',
        tradeTime: '08:52:33',
        orderNumber: 'caahE',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:51:52',
        orderNumber: 'caag8',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:51:51',
        orderNumber: 'caag6',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:51:52',
        orderNumber: 'caag4',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:51:01',
        orderNumber: 'caagW',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:51:01',
        orderNumber: 'caagV',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:49:25',
        orderNumber: 'caaf7',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限買',
        tradeDate: '2024/01/22',
        tradeTime: '08:49:25',
        orderNumber: 'caaf5',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:49:00',
        orderNumber: 'caaeq',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:52:10',
        orderNumber: 'caaep',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:48:57',
        orderNumber: 'caaeo',
    },
    {
        symbol: 'QFF小型台積電期貨02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:48:03',
        orderNumber: 'caaen',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:56',
        orderNumber: 'caadl',
    },
    {
        symbol: 'DKF廣達電腦股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:40',
        orderNumber: 'caacT',
    },
    {
        symbol: 'DXF緯創資通股份有限03',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:25',
        orderNumber: 'caacK',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:49:16',
        orderNumber: 'caacH',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:50:07',
        orderNumber: 'caacD',
    },
    {
        symbol: 'GHF技嘉期貨02',
        orderType: '新倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:19',
        orderNumber: 'caacy',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:04',
        orderNumber: 'caacc',
    },
    {
        symbol: 'DXF緯創資通股份有限02',
        orderType: '平倉ROD',
        tradingCategory: '限賣',
        tradeDate: '2024/01/22',
        tradeTime: '08:45:00',
        orderNumber: 'caabP',
    },
];
