import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Group, TextInput } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolWatchListGroup, } from '~/modules/WatchList/useSymbolWatchListGroup';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { usePopularResource } from '~/modules/screener/containers/useStockScreenerResource';
import { scrollbar2Css } from '~/css/scrollbarCss';
/** 控制面板本體元件 */
export const WatchListModal = memo(function WatchListModal() {
    const [opened, { open, close }] = useDisclosure(false);
    return (<div>
      <Modal opened={opened} onClose={close} title='設定' css={classes.modalCss}>
        <WatchListSelect baseGroupName='investment'/>
        <WatchListName />
        <WatchListSymbol />
      </Modal>

      <Group position='center'>
        <Button onClick={open}>設定</Button>
      </Group>
    </div>);
});
const watchListModalStore = proxy({
    watchListGroup: 'group0',
});
/** 群組選擇元件 */
const WatchListSelect = memo(function WatchListSelect(props) {
    const state = useSnapshot(watchListModalStore);
    const { state: watchListState, acts: watchListActs1 } = useSymbolWatchListGroup('demo_list_test');
    const watchListGroupArray = watchListState.symbolListNameArray || {};
    //強制排群組順序
    const orderedKeys = ['group0', 'group1', 'group2', 'group3', 'group4'];
    return (<div css={classes.groupSelecter}>
        {orderedKeys.map((key, index) => {
            const groupName = watchListGroupArray[key]?.name ?? '';
            const displayIndex = index + 1;
            const displayName = groupName[0]?.length > 0 ? groupName : '群組' + displayIndex;
            return (<div key={key} onClick={() => {
                    watchListModalStore.watchListGroup = key;
                }} css={css `
                ${fill_horizontal_all_center};
                border: 1px solid ${state.watchListGroup === key ? '#cccccc' : '#454545'};
                cursor: pointer;
              `}>
              {displayName}
            </div>);
        })}
      </div>);
});
const WatchListName = memo(function WatchListName() {
    const { state: watchListState, acts: watchListActs } = useSymbolWatchListGroup('demo_list_test');
    const state = useSnapshot(watchListModalStore);
    const [modifyGroupName, setModifyGroupName] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const watchListGroupArray = watchListState.symbolListNameArray || {};
    const groupName = watchListGroupArray[state.watchListGroup]?.name[0] ?? '';
    const displayName = groupName?.length > 0 ? groupName : '群組';
    useEffect(() => setModifyGroupName(false), [state.watchListGroup]);
    return (<div css={classes.groupName}>
      {/** 顯示群組名稱 */}
      {!modifyGroupName && (<TextInput value={displayName} disabled={true}/>)}
      {/** 編輯模式跳出輸入匡 */}
      {modifyGroupName && (<TextInput value={newGroupName} placeholder='重新命名' onChange={(event) => event.target.value.length >= 0 && setNewGroupName(event.target.value)}/>)}
      {/** 編輯模式跳出輸完成 */}
      {modifyGroupName && (<div css={css `
            ${fill_horizontal_all_center};
            width: 100%;
          `}>
          <div onClick={() => {
                watchListActs.renameWatchListGroup(newGroupName, state.watchListGroup);
                setModifyGroupName(false);
                setNewGroupName('');
            }} css={css `
              ${fill_horizontal_all_center};
              background-color: #33aa44;
              width: 50%;
              height: 36px;
            `}>
            完成
          </div>
          <div onClick={() => setModifyGroupName(false)} css={css `
              ${fill_horizontal_all_center};
              background-color: #aa3344;
              width: 50%;
              height: 36px;
            `}>
            取消
          </div>
        </div>)}

      {/** 是否啟動輸入面板 */}
      {!modifyGroupName && (<div onClick={() => {
                setModifyGroupName(true);
                setNewGroupName(groupName);
            }} css={css `
            ${fill_horizontal_all_center};
            background-color: #33aa44;
            width: 100%;
            height: 36px;
          `}>
          編輯名稱
        </div>)}
    </div>);
});
//自選股檔數
//熱門股直接選
export const WatchListSymbol = memo(function WatchListSymbol() {
    const { state: watchListState, acts: watchListActs } = useSymbolWatchListGroup('demo_list_test');
    const state = useSnapshot(watchListModalStore);
    const watchListGroupArray = watchListState.symbolListNameArray || {};
    const selectedGroup = Object.entries(watchListGroupArray).find(([key]) => key === state.watchListGroup);
    const symbolsArray = selectedGroup ? selectedGroup[1]?.symbols : [];
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    const [inputValue, setInputValue] = useState('');
    //----------------------------------------------------------------
    const popularEmptyData = [
        {
            date: '',
            symbol: '',
            value: 0,
            volume: 0,
        },
    ];
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const popularData = usePopularResource({ date: day0.format('YYYY-MM-DD'), sort: 'value', limit: 30 }).data ??
        popularEmptyData;
    //----------------------------------------------------------------
    //搜尋列
    const searchMatchSymbol = searchDefaultSymbols.filter(s => s.symbol.includes(inputValue) || s.name.includes(inputValue));
    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            const isSymbolInList = searchDefaultSymbols.some(s => s.symbol === inputValue);
            if (isSymbolInList) {
                watchListActs.addSymbol(inputValue, state.watchListGroup);
                setInputValue('');
            }
        }
    };
    const searchResultSymbol = inputValue.length > 0 ? searchMatchSymbol.map(s => s.symbol) : popularData.map(s => s.symbol);
    return (<div css={classes.body}>
      <div css={css `
          display: grid;
          grid-template-columns: 50% 50%;
          width: 100%;
          height: 100%;
        `}>
        <div css={css `
            ${scrollbar2Css};
            background-color: #232323;
          `}>
          <div css={css `
              ${fill_horizontal_all_center};
              height: 30px;
              gap: 12px;
            `}>
            <div>熱門</div>
            <div>搜索</div>
          </div>
          <div>
            <input placeholder='搜尋代號或名稱' onChange={e => setInputValue(e.target.value)} value={inputValue} onKeyDown={event => handleEnterPress(event)} css={css `
                height: 30px;
                width: 100%;
              `}/>
          </div>
          <div css={css `
              ${fill_vertical_cross_center};
              ${scrollbar2Css};
              height: calc(100% - 60px);
              align-items: start;
              box-sizing: border-box;
              background-color: #552323;
            `}>
            {searchResultSymbol.map((s, index) => {
            return (<div key={index} css={classes.symbolItem} onClick={() => watchListActs.addSymbol(s, state.watchListGroup)}>
                  {s} {dictionary[s]}
                </div>);
        })}
          </div>
        </div>
        <div css={css `
            ${fill_vertical_cross_center};
            ${scrollbar2Css};
            background-color: #232323;
          `}>
          {symbolsArray?.map((s, index) => {
            return (<div css={classes.symbolItem} key={index}>
                {s}
                {dictionary[s]}
                <div onClick={() => {
                    watchListActs.removeSymbol(s, state.watchListGroup);
                }}>
                  X
                </div>
              </div>);
        })}
        </div>
      </div>
    </div>);
});
const classes = {
    modalCss: css `
    &.mantine-Modal-root .mantine-Modal-content {
      height: 90vh;
      width: 100%;
      overflow: hidden;
    }
    .mantine-1q36a81 {
      padding: 8px;
      height: calc(100% - 88px);
    }

    .mantine-1q36a81 {
      padding: 8px;
      height: calc(100% - 54px);
    }
  `,
    groupSelecter: css `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    gap: 4px;
    height: 30px;
    background-color: #242424;
    font-size: 14px;
  `,
    groupName: css `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 30% 30% 40%;
    height: 40px;
    background-color: #565656;
  `,
    body: css `
    ${fill_vertical_cross_center};
    height: calc(100% - 70px);
    background-color: #454545;
    font-size: 14px;
  `,
    symbolList: css `
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    width: 50%;
    height: 100%;
    padding: 4px;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #555;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 4px;
      border-radius: 5px;
      background-color: #ccc;
    }
  `,
    symbolItem: css `
    ${fill_horizontal_cross_center};
    width: calc(99% - 1px);
    height: 30px;
    border: 1px solid #555555;
    gap: 12px;
    transform: translate(0px, 0px);
    user-select: none;
    flex-shrink: 0;
  `,
};
