import { css } from '@emotion/react';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { WatchListModal } from './component/WatchListModal';
export const investmentStore = proxy({
    demoSymbol: '2330',
    currentSymbol: '2330',
});
export const Investment_TopBar = memo(function Investment_TopBar() {
    const currentSymbol = useSnapshot(investmentStore).currentSymbol;
    return (<div css={css `
        ${fill_horizontal_cross_center}
        background-color: #364563;
      `}>
      -
      <WatchListModal />
    </div>);
});
