import { memo } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import dayAPI from '~/utils/dayAPI';
export const TradeAnalyzeProfitChart = memo(function TradeAnalyzeProfitChart(props) {
    const data = props.data;
    const domain = props.domain;
    return (<ResponsiveContainer width='100%' height='100%'>
      <LineChart width={500} height={300} data={data} margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }}>
        <CartesianGrid strokeDasharray='3 3'/>
        <XAxis type='number' dataKey='closePositionTime' domain={domain} tickFormatter={str => dayAPI(str * 1000).format('HH:mm:ss')}/>
        <YAxis />
        <Tooltip labelFormatter={t => dayAPI(Number(t) * 1000).format('HH:mm:ss')}/>
        <Line isAnimationActive={false} type='linear' dot={true} dataKey='accumulatedProfit' stroke='#aa4444' activeDot={{ r: 2 }} strokeWidth={1}/>
      </LineChart>
    </ResponsiveContainer>);
});
