export const tradeAnalyzeSummary = (data) => {
    // 計算總數值
    const totalQuantity = data.reduce((acc, trade) => acc + trade.openPositionBuyQuantity, 0);
    const totalTransactionFees = data.reduce((acc, trade) => acc + trade.transactionFees, 0);
    const totalTransactionTax = data.reduce((acc, trade) => acc + trade.transactionTax, 0);
    const totalNetProfit = data.reduce((acc, trade) => acc + trade.netProfit, 0);
    // 計算項次的總數
    const totalCount = data.length;
    // 計算netProfit>0的次數
    const positiveNetProfitCount = data.filter(trade => trade.netProfit > 0).length;
    // 計算netProfit>0的平均值
    const positiveNetProfitTotal = data.reduce((acc, trade) => (trade.netProfit > 0 ? acc + trade.netProfit : acc), 0);
    const positiveNetProfitAverage = positiveNetProfitCount > 0 ? positiveNetProfitTotal / positiveNetProfitCount : 0;
    // 計算netProfit<0的次數
    const negativeNetProfitCount = totalCount - positiveNetProfitCount;
    // 計算netProfit<0的平均值
    const negativeNetProfitTotal = data.reduce((acc, trade) => (trade.netProfit < 0 ? acc + trade.netProfit : acc), 0);
    const negativeNetProfitAverage = negativeNetProfitCount > 0 ? negativeNetProfitTotal / negativeNetProfitCount : 0;
    // 計算最大和最小netProfit
    const maxNetProfit = Math.max(...data.filter(trade => trade.netProfit > 0).map(trade => trade.netProfit));
    const minNetProfit = Math.min(...data.filter(trade => trade.netProfit < 0).map(trade => trade.netProfit));
    // Find trade with max net profit
    const maxProfitTrade = data.find(trade => trade.netProfit === maxNetProfit);
    // Find trade with min net profit
    const minProfitTrade = data.find(trade => trade.netProfit === minNetProfit);
    return {
        totalQuantity,
        totalNetProfit,
        totalTransactionFees,
        totalTransactionTax,
        totalCount,
        positiveNetProfitCount,
        negativeNetProfitCount,
        positiveNetProfitAverage,
        negativeNetProfitAverage,
        maxNetProfit,
        minNetProfit,
        maxProfitTrade,
        minProfitTrade,
    };
};
